// libraries
import React from 'react';
import { Router } from '@reach/router';
import { PageRendererProps } from 'gatsby';

import NotFound from 'pages/404';
// import Join from 'pages/join';
// import AccessLink from 'pages/access-link';
// import Activate from 'pages/activate';
// import JoinTeam from 'pages/teams/join';
// import SignUp from 'pages/signup';
import Home from 'pages/_home';

import Head from 'components/Head';

export default function App({ location }: PageRendererProps) {
  // prettier-ignore
  // eslint-disable-next-line
  try { window; } catch (err) { return <Head />; }
  return (
    <Router location={location} id="router">
      <Home path="/" />
      {/* <Join path="/join/:peer_id/:host_secret" />
      <AccessLink path="/guest-access/:deprecated/:access_link_id" />
      <Activate path="/activate/:code" />
      <JoinTeam path="/teams/:team_id/join" />
      <SignUp path="/signup/anonymous" isAnonymous />
      <SignUp path="/signup" /> */}
      <NotFound default />
    </Router>
  );
}
